import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import nl2br from 'react-nl2br'
import Layout from '../components/Layout'

import menu_grill from '../assets/images/menu_grill.jpg'
import menu_pizza from '../assets/images/menu_pizza.jpg'
import menu_coffee from '../assets/images/menu_coffee.jpg'

const MenuPage = () => {
  // Pull content from DatoCMS via GraphQL
  const specialsQuery = useStaticQuery(graphql`
  query specialsQuery {
    allDatoCmsWeeklySpecialsMenu(
      sort: {fields: meta___publishedAt, order: DESC}
      limit: 1
    ) {
      nodes {
        weekBeginning
        weekEnding
        monday
        tuesday
        wednesday
        thursday
        friday
        pdf {
          url
        }
      }
    }
  }`);

  // Our DatoCMS data structure has everything several layers deep, so move it all into convenient constants
  const data = specialsQuery.allDatoCmsWeeklySpecialsMenu.nodes[0];
  const weekBeginning = data.weekBeginning;
  const weekEnding = data.weekEnding;
  const monday = data.monday;
  const tuesday = data.tuesday;
  const wednesday = data.wednesday;
  const thursday = data.thursday;
  const friday = data.friday;
  const pdf = data.pdf.url;

  // We also need to format our dates to human-friendly
  const formatOptions = {
    year: "numeric",
    month: "long",
    day: "2-digit",
    timeZone: 'GMT' // Our stored dates are already locally formatted, so we don't want JS to do any TZ manipulation. Just pretend we're GMT.
  };
  const fmtWeekBeginning = new Intl.DateTimeFormat("en-US", formatOptions).format(new Date(weekBeginning));
  const fmtWeekEnding = new Intl.DateTimeFormat("en-US", formatOptions).format(new Date(weekEnding));

  // We're finally ready to actually render our components, using our CMS-provided data!
  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>Our Menu</h2>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner menu">
            <section>
              <h2 className="major">Weekly Specials</h2>
              <p className="date">{fmtWeekBeginning} - {fmtWeekEnding}</p>
              <ul className="actions">
                <li><a href={pdf} className="button primary" target="_blank" rel="noreferrer">Download Weekly Specials Menu</a></li>
              </ul>
            </section>
            <section>
              <h3 className="major">Monday</h3>
              <p>{nl2br(monday.trim())}</p>
              <h3 className="major">Tuesday</h3>
              <p>{nl2br(tuesday.trim())}</p>
              <h3 className="major">Wednesday</h3>
              <p>{nl2br(wednesday.trim())}</p>
              <h3 className="major">Thursday</h3>
              <p>{nl2br(thursday.trim())}</p>
              <h3 className="major">Friday</h3>
              <p>{nl2br(friday.trim())}</p>
            </section>
          </div>
        </div>
        <div className="wrapper alt style3">
          <div className="inner menu">
            <h2 className="major">Our Regular Items</h2>
            <section>
              <span className="image fit"><img src={menu_grill} alt="Grill" /></span>
              {/* <span className="image fit"><img src={menu_pizza} alt="Pizza" /></span> */}
              <span className="image fit"><img src={menu_coffee} alt="Coffee" /></span>
            </section>
          </div>
        </div>
      </section>
    </Layout>);
};

export default MenuPage;
